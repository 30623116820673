import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA OPTIONS------
    getProduksiDOC(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/distribusi/master/produksi-doc", { params: credentials })
    },


    // -----API HISTORY LIST------
    getRiwayatSendDoc(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/distribusi/send-doc/all", { params: credentials })
    },


    // -----API DATA LIST------
    getHatcheryList() {
        ApiService.setHeader();
        return ApiService.get("v2/nasional/distribusi/hatchery")
    },
    getSendDocList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/distribusi/send-doc", { params: credentials })
    },
    getSendDocDetailList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/distribusi/send-doc/detail", { params: credentials })
    },


    // ------API PROCESS DATA------    
    getSendDocEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/distribusi/send-doc/edit", { params: credentials })
    },
    getSendDocPerbaikan(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/distribusi/send-doc/perbaikan", { params: credentials })
    },
    addSendDoc(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/distribusi/send-doc", credentials)
    },
    updateSendDoc(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/distribusi/send-doc", credentials)
    },
    updateSendDocPerbaikan(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/nasional/distribusi/send-doc/perbaikan", credentials)
    },
    deleteSendDoc(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/distribusi/send-doc", { data: credentials })
    },


    getAjukanPerbaikan(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/nasional/distribusi/ajukan-perbaikan", { params: credentials })
    },
    addAjukanPerbaikan(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/nasional/distribusi/ajukan-perbaikan", credentials)
    },
    deleteAjukanPerbaikan(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/nasional/distribusi/ajukan-perbaikan", { data: credentials })
    },
}